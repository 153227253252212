const language = {
  tr: {
    appTitle: "A-Flying'e Hoşgeldiniz",
    appDescription: "Tüm ihtiyaçlarınız için son uygulamanız.",
    downloadFromPlayStore: "Play Store'dan indir",
    downloadFromAppStore: "App Store'dan indir",
    fileExtensionError: "Dosya uzantısı hatalı",
    saveError: "Kaydedilemedi",
    saveSuccess: "Başarıyla kaydedildi",
    addUser: "Kullanıcı Ekle",
    userZipCode: "Posta Kodu",
    selectPackage: "Paket Seç",
    package3Monthly: "3 Aylık Paket",
    package6Monthly: "6 Aylık Paket",
    packageYearly: "Yıllık Paket",
    saveUser: "Kullanıcıyı Kaydet",
    noProductFound: "Ürün bulunamadı.",
    selectPrintPrice: "Baskı Fiyatını Seç",
    noPrintPrices: "Baskı fiyatı bulunamadı.",
    selectedPrice: "Seçilen Fiyat",
    confirmSelection: "Seçimi Onayla",
    nextStep: "Sonraki Adım",
    previousStep: "Önceki Adım",
    uploadLogo: "Lütfen logonuzu yükleyin",
    addImage: "Resim Ekle",
    imageDescription: "Logonuz veya profil resminiz görünecek.",
    selectPaymentMethod: "Ödeme Yöntemi Seçin",
    userIBAN: "IBAN",
    userBankAccountOwnerName: "Hesap Sahibinin Adı",
    userBankAccountOwnerSurname: "Hesap Sahibinin Soyadı",
    userBankName: "Banka Adı",
    userAccountNumber: "Hesap Numarası",
    paypalEmail: "PayPal E-posta",
    userPaypalAccountOwnerName: "PayPal Hesap Sahibi Adı",
    userPaypalAccountOwnerSurname: "PayPal Hesap Sahibi Soyadı",
    userPaypalCountry: "PayPal Hesap Ülkesi",
    explanation: "Seçtiğiniz ödeme yöntemine göre gerekli bilgileri girin.",
    bankInformationDescription: "Banka veya PayPal bilgilerinizi sağlayın.",
    bankDetailsDescription: "Banka hesap bilgilerinizi aşağıda girin.",
    paypalDetailsDescription: "PayPal hesap bilgilerinizi aşağıda girin.",
    postalCodeInfo: "Daha iyi hizmet almanız için posta kodunuzu girin.",
    countryInfo: "Yaşadığınız ülkeyi seçin. Bu bilgi kayıt için gereklidir.",
    priceOptions: "Fiyat seçenekleri",
    noImagesAvailable: "Seçili pakete ait görüntü bulunamadı.",
    selectBackground: "Lütfen kullanmak istediğiniz arkaplanları seçiniz.",
    suppportForm: "Destek Formu",
    message: "Mesaj",
    send: "Gönder",
    messageSent: "Mesajınız gönderildi!",
    errorMessageSent: "Mesajınız gönderilemedi!",
    supportFormDescription: "Destek talebinizi aşağıdaki formdan iletin.",
    paypalNumber: "PayPal Numarası",
    kvkkConsentMessage: "Okudum ve kabul ediyorum",
    kvkkLinkText: "Kişisel Verilerin Gizliliği Kanunu ve Kullanım Koşullarını",
    personalInfo: "Kişisel Bilgiler",
    infoDescription: "Lütfen aşağıdaki bilgileri doldurun.",
    name: "İsim",
    nameDescription: "Adınızı girin.",
    surname: "Soyisim",
    surnameDescription: "Soyadınızı girin.",
    userName: "Kullanıcı Adı",
    usernameDescription: "Kullanıcı adınızı seçin.",
    taxNumber: "Vergi Numarası",
    taxNumberDescription: "Vergi numaranızı girin.",
    phoneNumber: "Telefon Numarası",
    phoneDescription: "Telefon numaranızı girin.",
    email: "E-posta",
    emailDescription: "Geçerli bir e-posta adresi girin.",
    password: "Şifre",
    passwordDescription: "Güçlü bir şifre belirleyin.",
    companyName: "Şirket Adı",
    companyDescription: "Şirket adınızı girin.",
    fieldsRequired: "Tüm alanların doldurulması gerekmektedir.",
    invalidEmail: "Geçersiz e-posta adresi.",
    invalidPhoneNumber: "Geçersiz telefon numarası.",
    ibanOrPaypalRequired:
      "IBAN veya PayPal numarasından en az birinin girilmiş olması gerekiyor.",
    nameSurnameRequired: "İsim ve soyisim zorunludur.",
    bankNameRequired: "Banka adı zorunludur.",
    bankAccountOwnerRequired:
      "Banka hesabı sahibinin adı ve soyadı gereklidir.",
    bankAccountRequired:
      "En az bir banka hesabı veya PayPal numarası girmelisiniz.",
    bankInformation: "Banka Bilgileri",
    paypal: "PayPal",
    invalidZipCode: "Posta kodu 5 haneli olmalıdır.",
    countryRequired: "Ülke seçimi gereklidir.",
    packageRequired: "Bir paket seçilmelidir.",
    paymentPlanRequired: "Bir ödeme planı seçilmelidir.",
    backgroundRequired: "Bir arka plan seçilmelidir.",
    kvkkRequired: "KVKK onayı gereklidir.",
    backgroundSelectionLimit: "En az 1 arka plan resmi seçmelisiniz.",
    invalidIban: "Geçersiz IBAN formatı lütfen kontrol ediniz.",
    registerSuccess: "Kayıt Başarılı!",
    registerSuccessDescription: "Kaydolduğunuz için teşekkür ederiz.",
    registrationConfirmed: "Kaydınız onaylandı.",
    returnToApp: "Lütfen mobil uygulamaya geri dönün.",
    goToApp: "Uygulamaya Git",
    ibanunsupported: "IBAN doğrulaması desteklenmiyor:",
    ibanInvalidStart: "IBAN, ülke kodu ile başlamalıdır",
    ibanMustBe: "IBAN",
    ibanInvalidLength: "karakter uzunluğunda olmalıdır",
    ibanInvalidFormat: "Geçersiz IBAN formatı",
    invalidPaypalMail: "Geçersiz PayPal e-posta adresi.",
    perMonth: "Aylık",
    packageDescription: "Seçilen Paketin Açıklaması",
    userNameAlreadyExists: "Kullanıcı adı daha önce alınmış",
    taxNumberAlreadyExists: "Vergi numarası daha önce kayıtlı",
    emailAdressAlreadyExists: "E-posta adresi daha önce kayıtlı",
    phoneNumberAlreadyExists: "Telefon numarası daha önce kayıtlı",
    invalidUsername:
      "Geçersiz kullanıcı adı lütfen 3-20 karakter arası giriniz",
    packageLimitError: "Paket limitine ulaşıldı",
    maxImageSelection: "Maksimum resim seçimi yapıldı",
    share: "Paylaş",
    download: "İndir",
    shareAppWithYourFriends: "Uygulamamızı arkadaşlanırızla paylaşın!",
  },
  en: {
    appTitle: "Welcome to A-Flying",
    appDescription: "Your ultimate app for all your needs.",
    downloadFromPlayStore: "Download from Play Store",
    downloadFromAppStore: "Download from App Store",
    fileExtensionError: "File extension is incorrect",
    saveError: "Could not save",
    saveSuccess: "Successfully saved",
    addUser: "Add User",
    userZipCode: "Zip Code",
    selectPackage: "Select Package",
    package3Monthly: "3 Month Package",
    package6Monthly: "6 Month Package",
    packageYearly: "Yearly Package",
    saveUser: "Save User",
    noProductFound: "No product found.",
    selectPrintPrice: "Select Print Price",
    noPrintPrices: "No print prices found.",
    selectedPrice: "Selected Price",
    confirmSelection: "Confirm Selection",
    nextStep: "Next Step",
    previousStep: "Previous Step",
    uploadLogo: "Please upload your logo",
    addImage: "Add Image",
    imageDescription: "Your logo or profile picture will be displayed.",
    personalInfo: "Personal Information",
    infoDescription: "Provide your personal details to complete registration.",
    name: "First Name",
    nameDescription: "Enter your first name.",
    surname: "Last Name",
    surnameDescription: "Enter your last name.",
    userName: "Username",
    usernameDescription: "Choose your username.",
    taxNumber: "Tax Number",
    taxNumberDescription: "Enter your tax number.",
    phoneNumber: "Phone Number",
    phoneDescription: "Enter your phone number.",
    email: "Email",
    emailDescription: "Enter a valid email address.",
    password: "Password",
    passwordDescription: "Choose a strong password.",
    companyName: "Company Name",
    companyDescription: "Enter your company name.",
    fieldsRequired: "All fields are required.",
    invalidEmail: "Invalid email address.",
    invalidPhoneNumber: "Invalid phone number.",
    selectPaymentMethod: "Select Payment Method",
    userIBAN: "IBAN",
    userBankAccountOwnerName: "Bank Account Owner Name",
    userBankAccountOwnerSurname: "Bank Account Owner Surname",
    userBankName: "Bank Name",
    userAccountNumber: "Account Number",
    paypalEmail: "PayPal Email",
    userPaypalAccountOwnerName: "PayPal Account Owner Name",
    userPaypalAccountOwnerSurname: "PayPal Account Owner Surname",
    userPaypalCountry: "PayPal Account Country",
    explanation: "Enter the required details based on your payment method.",
    bankInformationDescription: "Provide your bank or PayPal information.",
    bankDetailsDescription: "Enter your bank account details below.",
    paypalDetailsDescription: "Enter your PayPal account details below.",
    postalCodeInfo: "Enter your postal code for better service.",
    countryInfo:
      "Select your country from the list. This information is required for registration.",
    priceOptions: "Price Options",
    noImagesAvailable: "No images found for the selected package.",
    selectBackground: "Please select the backgrounds you want to use.",
    suppportForm: "Support Form",
    message: "Message",
    send: "Send",
    messageSent: "Your message has been sent!",
    errorMessageSent: "Your message could not be sent!",
    supportFormDescription: "Submit your support request via the form below.",
    paypalNumber: "PayPal Number",
    kvkkConsentMessage: "I have read and accept the",
    kvkkLinkText: "Personal Data Protection Law and Terms",
    ibanOrPaypalRequired:
      "At least one of IBAN or PayPal number must be provided.",
    nameSurnameRequired: "First name and last name are required.",
    bankNameRequired: "Bank name is required.",
    bankAccountOwnerRequired:
      "Bank account owner's name and surname are required.",
    bankAccountRequired:
      "You must enter at least one bank account or PayPal number.",
    bankInformation: "Bank Information",
    paypal: "PayPal",
    invalidZipCode: "Postal code must be 5 digits.",
    countryRequired: "Country selection is required.",
    packageRequired: "A package must be selected.",
    paymentPlanRequired: "A payment plan must be selected.",
    backgroundRequired: "A background must be selected.",
    kvkkRequired: "KVKK consent is required.",
    backgroundSelectionLimit: "You must select at least 1 background images.",
    invalidIban: "Invalid IBAN format please check.",
    registerSuccess: "Registration Successful!",
    registerSuccessDescription: "Thank you for registering.",
    registrationConfirmed: "Your registration has been confirmed.",
    returnToApp: "Please return to the mobile application.",
    goToApp: "Go to App",
    ibanunsupported: "IBAN validation not supported for country:",
    ibanInvalidStart: "IBAN must start with the country code",
    ibanMustBe: "IBAN must be",
    ibanInvalidLength: "characters long for",
    ibanInvalidFormat: "Invalid IBAN format for",
    invalidPaypalMail: "Invalid PayPal email address.",
    perMonth: "per month",
    packageDescription: "Selected Package Description",
    userNameAlreadyExists: "Username already exists",
    taxNumberAlreadyExists: "Tax number already exists",
    emailAdressAlreadyExists: "Email address already exists",
    phoneNumberAlreadyExists: "Phone number already exists",
    invalidUsername: "Invalid username please enter 3-20 characters",
    packageLimitError: "Package limit reached",
    maxImageSelection: "Maximum image selection reached",
    share: "Share",
    download: "Download",
    shareAppWithYourFriends: "Share our app with your friends!",
  },
  de: {
    appTitle: "Willkommen bei A-Flying",
    appDescription: "Ihre ultimative App für all Ihre Bedürfnisse.",
    downloadFromPlayStore: "Vom Play Store herunterladen",
    downloadFromAppStore: "Vom App Store herunterladen",
    fileExtensionError: "Dateierweiterung ist falsch",
    saveError: "Konnte nicht speichern",
    saveSuccess: "Erfolgreich gespeichert",
    addUser: "Benutzer hinzufügen",
    userZipCode: "Postleitzahl",
    selectPackage: "Paket auswählen",
    package3Monthly: "3 Monatspaket",
    package6Monthly: "6 Monatspaket",
    packageYearly: "Jahrespaket",
    saveUser: "Benutzer speichern",
    noProductFound: "Kein Produkt gefunden.",
    selectPrintPrice: "Druckpreis auswählen",
    noPrintPrices: "Keine Druckpreise gefunden.",
    selectedPrice: "Ausgewählter Preis",
    confirmSelection: "Auswahl bestätigen",
    nextStep: "Weiter",
    previousStep: "Zurück",
    uploadLogo: "Bitte laden Sie Ihr Logo hoch",
    addImage: "Bild hinzufügen",
    imageDescription: "Ihr Logo oder Profilbild wird angezeigt.",
    personalInfo: "Persönliche Informationen",
    infoDescription: "Bitte füllen Sie die folgenden Informationen aus.",
    name: "Vorname",
    nameDescription: "Geben Sie Ihren Vornamen ein.",
    surname: "Nachname",
    surnameDescription: "Geben Sie Ihren Nachnamen ein.",
    userName: "Benutzername",
    usernameDescription: "Wählen Sie Ihren Benutzernamen.",
    taxNumber: "Steuernummer",
    taxNumberDescription: "Geben Sie Ihre Steuernummer ein.",
    phoneNumber: "Telefonnummer",
    phoneDescription: "Geben Sie Ihre Telefonnummer ein.",
    email: "E-Mail",
    emailDescription: "Geben Sie eine gültige E-Mail-Adresse ein.",
    password: "Passwort",
    passwordDescription: "Wählen Sie ein sicheres Passwort.",
    companyName: "Firmenname",
    companyDescription: "Geben Sie Ihren Firmennamen ein.",
    fieldsRequired: "Alle Felder müssen ausgefüllt werden.",
    invalidEmail: "Ungültige E-Mail-Adresse.",
    invalidPhoneNumber: "Ungültige Telefonnummer.",
    selectPaymentMethod: "Zahlungsmethode auswählen",
    userIBAN: "IBAN",
    userBankAccountOwnerName: "Kontoinhaber Name",
    userBankAccountOwnerSurname: "Kontoinhaber Nachname",
    userBankName: "Bankname",
    userAccountNumber: "Kontonummer",
    paypalEmail: "PayPal E-Mail",
    userPaypalAccountOwnerName: "PayPal-Kontoinhaber Vorname",
    userPaypalAccountOwnerSurname: "PayPal-Kontoinhaber Nachname",
    userPaypalCountry: "PayPal-Kontoland",
    explanation:
      "Geben Sie die erforderlichen Angaben basierend auf Ihrer Zahlungsmethode ein.",
    bankInformationDescription:
      "Geben Sie Ihre Bank- oder PayPal-Informationen ein.",
    bankDetailsDescription: "Geben Sie Ihre Bankkontodaten unten ein.",
    paypalDetailsDescription: "Geben Sie Ihre PayPal-Kontodaten unten ein.",
    postalCodeInfo: "Geben Sie Ihre Postleitzahl für besseren Service ein.",
    countryInfo:
      "Wählen Sie Ihr Land aus der Liste. Diese Informationen sind für die Registrierung erforderlich.",
    priceOptions: "Preisoptionen",
    noImagesAvailable: "Keine Bilder für das ausgewählte Paket gefunden.",
    selectBackground:
      "Bitte wählen Sie die Hintergründe aus, die Sie verwenden möchten.",
    suppportForm: "Support-Formular",
    message: "Nachricht",
    send: "Senden",
    messageSent: "Ihre Nachricht wurde gesendet!",
    errorMessageSent: "Ihre Nachricht konnte nicht gesendet werden!",
    supportFormDescription:
      "Senden Sie Ihre Supportanfrage über das unten stehende Formular.",
    paypalNumber: "PayPal-Nummer",
    kvkkConsentMessage: "Ich habe die gelesen und akzeptiere die",
    kvkkLinkText: "Datenschutzgesetz und Nutzungsbedingungen",
    ibanOrPaypalRequired:
      "Es muss mindestens eine IBAN oder eine PayPal-Nummer eingegeben werden.",
    nameSurnameRequired: "Vorname und Nachname sind erforderlich.",
    bankNameRequired: "Der Bankname ist erforderlich.",
    bankAccountOwnerRequired:
      "Der Name und Nachname des Kontoinhabers sind erforderlich.",
    bankAccountRequired:
      "Sie müssen mindestens ein Bankkonto oder eine PayPal-Nummer angeben.",
    bankInformation: "Bankinformationen",
    paypal: "PayPal",
    invalidZipCode: "Die Postleitzahl muss 5 Stellen haben.",
    countryRequired: "Die Länderauswahl ist erforderlich.",
    packageRequired: "Es muss ein Paket ausgewählt werden.",
    paymentPlanRequired: "Es muss ein Zahlungsplan ausgewählt werden.",
    backgroundRequired: "Es muss ein Hintergrund ausgewählt werden.",
    kvkkRequired: "Die KVKK-Zustimmung ist erforderlich.",
    backgroundSelectionLimit:
      "Sie müssen mindestens 1 Hintergrundbilder auswählen.",
    invalidIban: "Ungültiges IBAN-Format bitte überprüfen.",
    registerSuccess: "Registrierung erfolgreich!",
    registerSuccessDescription: "Vielen Dank für Ihre Registrierung.",
    registrationConfirmed: "Ihre Registrierung wurde bestätigt.",
    returnToApp: "Bitte kehren Sie zur mobilen Anwendung zurück.",
    goToApp: "Zur App gehen",
    ibanunsupported: "IBAN-Validierung nicht unterstützt für Land:",
    ibanInvalidStart: "IBAN muss mit dem Ländercode beginnen",
    ibanMustBe: "IBAN muss",
    ibanInvalidLength: "Zeichen lang sein für",
    ibanInvalidFormat: "Ungültiges IBAN-Format für",
    invalidPaypalMail: "Ungültige PayPal-E-Mail-Adresse.",
    perMonth: "pro Monat",
    packageDescription: "Ausgewählte Paketbeschreibung",
    userNameAlreadyExists: "Kunden Daten bereits vorhanden",
    taxNumberAlreadyExists: "Steuernummer existiert bereits",
    emailAdressAlreadyExists: "E-Mail-Adresse existiert bereits",
    phoneNumberAlreadyExists: "Telefonnummer existiert bereits",
    invalidUsername: "Ungültiger Benutzername bitte geben Sie 3-20 Zeichen ein",
    packageLimitError: "Paketlimit erreicht",
    maxImageSelection: "Maximale Bildauswahl erreicht",
    share: "Teilen",
    download: "Herunterladen",
    shareAppWithYourFriends: "Teilen Sie unsere App mit Ihren Freunden!",
  },
};

export default language;
