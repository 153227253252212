import { createTheme } from "@mui/material/styles";
const theme = createTheme({
  typography: {
    fontFamily: "Inter",
  },
  palette: {
    primary: {
      main: "#304564", //Dark navy blue
    },
    secondary: {
      main: "#2BA8E4", //Turquoise
    },
  },
});

export default theme;
